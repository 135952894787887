<template>
    <v-row justify="center">
        <v-dialog v-model="showDialog"
                  persistent
                  max-width="600px"
                  overlay-color="#666666"
                  overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <span class="headline">Add API Key</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-alert border="left"
                                 colored-border
                                 :type="alert.type"
                                 :key="alert.message"
                                 dismissible
                                 dense
                                 elevation="2"
                                 v-for="alert in alerts"
                        >{{ alert.message }}</v-alert>
                        <v-row>
                            <v-col cols="12" v-if="isAdmin == true">
                                <v-text-field
                                        label="Account"
                                        v-model="current._account.name"
                                        :readonly=true
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="Reservoir"
                                        v-model="current._reservoir.name"
                                        :readonly=true
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                        label="User"
                                        v-model="current._user.username"
                                        :readonly=true
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="12" md="6">
                                <v-select
                                        :items=accessLevels
                                        v-model="current.access"
                                        label="Access Level*"
                                        item-text="Name"
                                        item-value="Value"
                                        :readonly="!isManager"
                                        required
                                ></v-select>
                            </v-col>
                            <v-col cols="6" sm="12" md="6">
                                <v-checkbox label="Active" v-model="current.active"></v-checkbox>
                            </v-col>
                            <v-col cols="12">
                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Notifications</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div style="text-align: left;">Select which notifications you would like to receive below:</div>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-checkbox label="Errors" v-model="current.notification.errors"></v-checkbox>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-checkbox label="Events" v-model="current.notification.events"></v-checkbox>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-checkbox label="Status" v-model="current.notification.status"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Channels</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <div style="text-align: left;">Select which channels you would like to receive your notifications on below:</div>
                                            <v-row>
                                                <v-col cols="4">
                                                    <v-checkbox label="Email" v-model="current.channels.email"></v-checkbox>
                                                </v-col>
                                                <v-col cols="4">
                                                    <v-checkbox label="App" v-model="current.channels.app"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="#FF0000" text @click="reGenerateAccessKey()">Re-generate</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="#FFFFFF" text v-clipboard="current.key" @success="copyOK" @error="copyError">Copy Key</v-btn>
                    <v-btn color="#00AAB9" text @click="showDialog = false">Close</v-btn>
                    <v-btn color="#00AAB9" text @click="saveAccessKey()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import resource from '@/resource';

    export default {
        name: "user-edit",
        props: {
            value: Object,
            show: Boolean
        },
        data() {
            return {
                accessLevels: [
                    { Value: "", Name: "None" },
                    { Value: "read", Name: "Read Only" },
                    { Value: "write", Name: "Read & Write" },
                    { Value: "full", Name: "Full Access" },
                ],
                isAdmin: false,
                isManager: false,
                alerts: []
            }
        },
        methods: {
            saveAccessKey: function() {
                let updatedKey = {
                    _id: this.current._id,
                    access: this.current.access,
                    active: this.current.active,
                    notification: {
                        errors: this.current.notification.errors,
                        events: this.current.notification.events,
                        status: this.current.notification.status,
                    },
                    channels: {
                        email: this.current.channels.email,
                        app: this.current.channels.app,
                    }
                }

                resource.accessKey.updateAccessKey(updatedKey).then(res => {
                    if(res.body.status == 'OK') {
                        this.current.updated = true;
                        this.showDialog = false;
                    }
                }, resource.processError);
            },
            copyOK: function() {
                //this.$store.commit('addAlert', { variant: "success", message: "Access key copied." });
                this.alerts.push({ type: "success", message: "Access key copied." });
            },
            copyError: function() {
                //this.$store.commit('addAlert', { variant: "danger", message: "Failed to copy the access key." });
                this.alerts.push({ type: "danger", message: "Failed to copy the access key." });
            }
        },
        computed: {
            showDialog: {
                get() {
                    return this.show
                },
                set (value) {
                    this.$emit('update:show', value);
                }
            },
            current: {
                get() {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        created() {
            if((['sysadmin','admin']).indexOf(this.$store.state.user.role) >= 0) this.isAdmin = true;
            if((['sysadmin','admin','manager']).indexOf(this.$store.state.user.role) >= 0) this.isManager = true;
        }
    }
</script>

<style scoped>

</style>