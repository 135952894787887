<template>
  <v-app id="inspire">
    <v-navigation-drawer
            v-model="drawer"
            :mini-variant=true
            app
            clipped
    >
      <v-list dense>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item link to="/dash/home" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Dashboard</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item link to="/dash/reservoirs" v-on="on">
              <v-list-item-icon>
                <v-icon>waves</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Reservoirs</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Reservoirs</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item link to="/dash/schedules" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Schedules</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Schedules</span>
        </v-tooltip>
        <v-tooltip right v-if="isAdmin === true">
          <template v-slot:activator="{ on }">
            <v-list-item link to="/dash/settings" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Settings</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
            app
            clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title>
        <img alt="echoing.io logo" src="@/assets/echoing_logo_white_nobg_small.png" style="height: 35px; position: relative; left: -15px;" >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn>
        {{ user.username }}
      </v-btn>
      <v-avatar>
        <img alt="User Avatar" :src="avatarUrl">
      </v-avatar>
      <v-btn icon @click.prevent="onLogout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content class="echo-dash-content">
      <v-container
              class="echo-dash-container fill-height"
              fluid
      >
        <v-row class="echo-dash-container-row fill-height">
          <div class="echo-dash-alerts" style="position: absolute; top: 15px; right: 15px; width: 320px; z-index: 999;">
            <v-alert border="left"
                     colored-border
                     :type="alert.type"
                     :key="index"
                     dismissible
                     dense
                     dark
                     elevation="2"
                     v-for="(alert,index) in alerts"
            >{{ alert.message }}</v-alert>
          </div>
          <v-col fluid class="echo-dash-container-col">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <v-footer app>
      <span>echoing.io &copy; 2020</span>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    name: "dashboard-page",
    methods: {
      onLogout: function() {
        let self = this;
        self.$http.get(self.$store.state.logoutUrl).then(() => {
          self.$router.push("/");
          self.$store.commit("setUser", null);
        }, res => {
          self.$store.commit("addAlert", { variant: "danger", message: "Logout request failed: " + res.statusText });
        });
      },
      dismissAlert: function(index) {
        let self = this;
        self.$store.commit("deleteAlert", index);
      }
    },
    data: function() {
      return {
        user: (this.$store.state.user ? this.$store.state.user : { username: "" }),
        alerts: this.$store.state.alerts,
        menus: [],
        drawer: true,
        isAdmin: false,
        avatarUrl: require("../assets/echoing_blue_whale.png")
      };
    },
    created() {
      let self = this;
      if(self.$store.state.user == null) self.$router.push('/');
      if(this.$store.state.user && (['sysadmin','admin','manager']).indexOf(this.$store.state.user.role) >= 0) this.isAdmin = true;
    }
  }
</script>

<style scoped>
  .echo-dash-container {
    padding-top: 0px;
  }

  .echo-dash-container-row, .echo-dash-container-col
  {
    padding: 0;
  }

  a.v-list-item--active {
    color: #00aab9;
  }


</style>