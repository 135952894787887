<template>
    <v-row justify="center">
        <v-dialog v-model="showDialog"
                  persistent
                  max-width="600px"
                  overlay-color="#666666"
                  overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <span class="headline">Send Access Invite</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" v-if="isAdmin == true">
                                <v-autocomplete
                                        label="Account*"
                                        v-model="current._account"
                                        :items="accounts"
                                        :loading="loadingAccounts"
                                        :search-input.sync="accountSearch"
                                        :required="isAdmin"
                                        hide-no-data
                                        item-text="name"
                                        placeholder="Search accounts here"
                                        clearable
                                        return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" v-if="isAdmin == true">
                                <v-autocomplete
                                        label="Reservoir*"
                                        v-model="current._reservoir"
                                        :items="reservoirs"
                                        :loading="loadingReservoirs"
                                        :search-input.sync="reservoirSearch"
                                        :required="isAdmin"
                                        hide-no-data
                                        item-text="name"
                                        placeholder="Search reservoirs here"
                                        clearable
                                        return-object
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        :items=accessLevels
                                        v-model="current.access"
                                        label="Access Level*"
                                        item-text="Name"
                                        item-value="Value"
                                        required
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        label="Email Address"
                                        type="email"
                                        v-model="current.emailAddress"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#00AAB9" text @click="showDialog = false">Close</v-btn>
                    <v-btn color="#00AAB9" text @click="sendInvite()">Send</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import resource from '@/resource';
    import debounce from "debounce";

    export default {
        name: "user-edit",
        props: {
            value: Object,
            show: Boolean
        },
        data() {
            return {
                current: {
                    _account: null,
                    _reservoir: null,
                    access: 'read',
                    emailAddress: null,
                },
                reservoirs: [],
                reservoirSearch: '',
                loadingReservoirs: false,
                accounts: [],
                accountSearch: '',
                loadingAccounts: false,
                accessLevels: [
                    { Value: "", Name: "None" },
                    { Value: "read", Name: "Read Only" },
                    { Value: "write", Name: "Read & Write" },
                    { Value: "full", Name: "Full Access" },
                ],
                isAdmin: false
            }
        },
        methods: {
            sendInvite: function() {
                let newInvite = {
                    name: this.current.name,
                    address: this.current.address,
                    billingEmailAddress: this.current.billingEmailAddress,
                    maxUsers: this.current.maxUsers,
                    billedUsers: this.current.billedUsers,
                    billingPeriod: this.current.billingPeriod
                }

                resource.accessInvite.sendInvite(newInvite).then(res => {
                    if(res.body.status == 'OK') {
                        this.current.updated = true;
                        this.showDialog = false;
                    }
                }, resource.processError);
            },
            searchAccount: async (value, self) => {
                if(!value) {
                    self.accounts = [];
                    self.current._account = null;
                }
                if(self.loadingAccounts) return;

                self.loadingAccounts = true;
                await resource.account.getAccounts({search: value }).then(res => {
                    if(res.body.status == 'OK') {
                        self.accounts = res.body.data.accounts;
                        self.loadingAccounts = false;
                    }
                }, resource.processError).finally(() => {
                    self.loadingAccounts = false;
                });
            },
            searchReservoir: async(value, self) => {
                if(!value) {
                    self.reservoirs = [];
                    self.current._reservoir = null;
                }
                if(self.loadingReservoirs) return;

                self.loadingReservoirs = true;
                resource.reservoir.getReservoirs({ search: value }).then(res => {
                    if(res.body.status == 'OK') {
                        self.reservoirs = res.body.data.reservoirs;
                        self.loadingReservoirs = false;
                    }
                }, resource.processError).finally(() => {
                    self.loadingReservoirs = false;
                });
            },
        },
        watch: {
            accountSearch(value) {
                if (!value) return;
                debounce(this.searchAccount, 200)(value, this);
            },
            reservoirSearch(value) {
                if (!value) return;
                debounce(this.searchReservoir, 200)(value, this);
            }
        },
        computed: {
            showDialog: {
                get() {
                    return this.show
                },
                set (value) {
                    this.$emit('update:show', value);
                }
            }
        },
        created() {
            if((['sysadmin','admin']).indexOf(this.$store.state.user.role) >= 0) this.isAdmin = true;
        }
    }
</script>

<style scoped>

</style>