//import Vue from 'vue';
import store from '../store';
import user from './user';
import account from './account';
import reservoir from './reservoir';
import accessKey from './accessKey';
export default {
    user: new user(),
    account: new account(),
    reservoir: new reservoir(),
    accessKey: new accessKey(),
    processResponse: (result, cb) => {
        if(result.body.status === "OK")
        {
            return cb(result);
        }
        else
        {
            store.commit("addAlert", { type: "error", message: "Failed to process " + this.name + " request: " + result.data.message });
        }
    },
    processError: (result) => {
        console.error(result);

        store.commit("addAlert", { type: "error", message:  "Request failed: " + result.statusText });
    }
};