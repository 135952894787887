<template>
    <v-tabs
            :v-model=true
            :vertical=true
            :icons-and-text=true
            class="fill-height"
    >
        <v-tabs-slider></v-tabs-slider>

        <v-tab key="1" href="#tab-reservoir">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{...tooltip, ...menu}">waves</v-icon>
                </template>
                <span>Reservoirs</span>
            </v-tooltip>
        </v-tab>
        <v-tab key="2" href="#tab-access-api">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{...tooltip, ...menu}">mdi-key</v-icon>
                </template>
                <span>Access Keys</span>
            </v-tooltip>
        </v-tab>
        <v-tab key="3" href="#tab-access-invites">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{...tooltip, ...menu}">mdi-email</v-icon>
                </template>
                <span>Access Invites</span>
            </v-tooltip>
        </v-tab>

        <v-tab-item key="1" value="tab-reservoir">
            <ReservoirList></ReservoirList>
        </v-tab-item>
        <v-tab-item key="2" value="tab-access-api">
            <AccessKeyList></AccessKeyList>
        </v-tab-item>
        <v-tab-item key="3" value="tab-access-invites">
            TODO...
        </v-tab-item>
    </v-tabs>
</template>

<script>
    import ReservoirList from './reservoirs/reservoir-list';
    import AccessKeyList from './accessKeys/accessKey-list';

    export default {
        name: "settings-page",
        data: function() {
            return {
                menu: false,
                tooltip: false,
                isAdmin: false
            }
        },
        components: {
            ReservoirList,
            AccessKeyList
        },
        created() {
            if((['sysadmin','admin']).indexOf(this.$store.state.user.role) >= 0) this.isAdmin = true;
        }
    }
</script>

<style scoped>
    a.v-tab--active {
        background-color: #666666;
    }
</style>