<template>
    <v-tabs
            :v-model=true
            :vertical=true
            :icons-and-text=true
            class="fill-height"
    >
        <v-tabs-slider></v-tabs-slider>

        <v-tab key="1" href="#tab-accounts">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{...tooltip, ...menu}">business</v-icon>
                </template>
                <span v-if="!isAdmin">Account</span>
                <span v-if="isAdmin">Accounts</span>
            </v-tooltip>
        </v-tab>
        <v-tab key="2" href="#tab-users">
            <v-tooltip right>
                <template v-slot:activator="{ on: tooltip }">
                    <v-icon v-on="{...tooltip, ...menu}">group</v-icon>
                </template>
                <span>Users</span>
            </v-tooltip>
        </v-tab>

        <v-tab-item key="1" value="tab-accounts">
            <div v-if="isAdmin">
                <AccountList></AccountList>
            </div>
            <div v-if="!isAdmin">
                User View
            </div>
        </v-tab-item>
        <v-tab-item key="2" value="tab-users">
            <UserList></UserList>
        </v-tab-item>
    </v-tabs>
</template>

<script>
    import UserList from '@/components/settings/user/user-list';
    import AccountList from '@/components/settings/account/account-list';

    export default {
        name: "settings-page",
        data: function() {
            return {
                menu: false,
                tooltip: false,
                isAdmin: false
            }
        },
        components: {
            UserList,
            AccountList
        },
        created() {
            if((['sysadmin','admin']).indexOf(this.$store.state.user.role) >= 0) this.isAdmin = true;
        }
    }
</script>

<style scoped>
    a.v-tab--active {
        background-color: #666666;
    }
</style>