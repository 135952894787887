<template>
    <v-row justify="center">
        <v-dialog v-model="showDialog"
                  persistent
                  max-width="600px"
                  overlay-color="#666666"
                  overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <span class="headline">Add User</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" v-if="isAdmin == true">
                                <v-autocomplete
                                        label="Account*"
                                        v-model="current._account"
                                        :items="accounts"
                                        :loading="loadingAccounts"
                                        :search-input.sync="accountSearch"
                                        :required="isAdmin"
                                        hide-no-data
                                        item-text="name"
                                        placeholder="Search accounts here"
                                        clearable
                                        return-object
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field label="Username*" v-model="current.username" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field label="Forename*" v-model="current.forename" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <v-text-field
                                        label="Surname*"
                                        v-model="current.surname"
                                        required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field label="Email*" v-model="current.emailAddress" type="email" required></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="6">
                                <v-checkbox label="Set Password" v-model="current.setPassword"></v-checkbox>
                                <v-checkbox label="Send Invite" v-model="current.emailInvite"></v-checkbox>
                            </v-col>
                            <v-col cols="8" sm="6">
                                <div v-if="current.setPassword">
                                    <v-text-field label="Password*" type="password"  v-model="current.password" required="current.updatePassword"></v-text-field>
                                    <v-text-field :rules="[rules.confirmPassword]" label="Confirm Password*" type="password" v-model="current.confirmPassword" required="current.updatePassword"></v-text-field>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select
                                        :items=roles
                                        v-model="current.role"
                                        label="Role*"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#00AAB9" text @click="showDialog = false">Close</v-btn>
                    <v-btn color="#00AAB9" text @click="saveUser()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import resource from '@/resource';
    import debounce from "debounce";

    export default {
        name: "user-edit",
        props: {
            value: Object,
            show: Boolean
        },
        data() {
            return {
                roles: [],
                current: {
                    role: 'user',
                    emailInvite: true
                },
                rules: {
                    confirmPassword: v => v == this.current.password || 'Passwords must match.',
                },
                accounts: [],
                accountSearch: '',
                loadingAccounts: false,
                isAdmin: false
            }
        },
        methods: {
            getRoles: function() {
                resource.user.getRoles().then(res => {
                    if(res.body.status === 'OK') {
                        this.roles = res.body.data;
                        this.current.role = 'user';
                    }
                }, resource.processError);
            },
            saveUser: function() {
                let newUser = {
                    _account: this.current._account._id,
                    username: this.current.username,
                    emailAddress: this.current.emailAddress,
                    forename: this.current.forename,
                    surname: this.current.surname,
                    role: this.current.role
                }

                if(this.current.setPassword && this.current.password) {
                    newUser.password = this.current.password;
                }

                if(this.current.emailInvite) {
                    newUser.emailInvite = this.current.emailInvite;
                }

                resource.user.addUser(newUser).then(res => {
                    if(res.body.status == 'OK') {
                        this.current.updated = true;
                        this.showDialog = false;
                    }
                }, resource.processError);
            },
            searchAccount: async (value, self) => {
                if(!value) {
                    self.accounts = [];
                    self.current._account = null;
                }
                if(self.loadingAccounts) return;

                self.loadingAccounts = true;
                await resource.account.getAccounts({search: value }).then(res => {
                    if(res.body.status == 'OK') {
                        self.accounts = res.body.data.accounts;
                        self.loadingAccounts = false;
                    }
                }, resource.processError).finally(() => {
                    self.loadingAccounts = false;
                });
            }
        },
        watch: {
            accountSearch(value) {
                if (!value) return;
                debounce(this.searchAccount, 200)(value, this);
            }
        },
        computed: {
            showDialog: {
                get() {
                    return this.show
                },
                set (value) {
                    this.$emit('update:show', value);
                }
            }
        },
        created() {
            this.getRoles();
            if((['sysadmin','admin']).indexOf(this.$store.state.user.role) >= 0) this.isAdmin = true;
        }
    }
</script>

<style scoped>

</style>