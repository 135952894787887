<template>
    <div>
        <EditDialog v-model="current" v-bind:show.sync="showEdit"></EditDialog>
        <AddDialog v-bind:show.sync="showAdd"></AddDialog>
        <SendInviteDialog v-bind:show.sync="showInvite"></SendInviteDialog>
        <div v-if="loading == true" class="text-center" style="margin-top: 100px;">
            <v-progress-circular :indeterminate=true></v-progress-circular>
        </div>
        <v-data-table
                v-if="loading == false"
                v-model="selected"
                :headers="headers"
                :items="reservoirs"
                item-key="name"
                class="elevation-1"
        >
            <template v-slot:top>
                <v-layout style="margin: 10px;">
                    <h2 style="margin-right: 30px;">Reservoirs</h2>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="addReservoir()">
                        <v-icon>mdi-plus</v-icon>
                        Add Reservoir
                    </v-btn>
                    <v-btn @click.stop="sendInvite()" style="margin-left: 5px;">
                        <v-icon style="margin-right: 5px;">mdi-email</v-icon>
                        Send Invite
                    </v-btn>
                </v-layout>
            </template>
            <template v-slot:item.accessType="{ item }">
                {{ accessTypes[item.accessType] }}
            </template>
            <template v-slot:item.accessMethod="{ item }">
                {{ accessMethods[item.accessMethod] }}
            </template>
            <template v-slot:item.created="{ item }">
                {{ item.created | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="item.userAccess === 'full' || ('sysadmin','admin','manager').indexOf(currentUser.role) >= 0" fab x-small v-on="on" @click.stop="editReservoir(item)"><v-icon>edit</v-icon></v-btn>
                    </template>
                    <span>Edit Account</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn v-if="item.userAccess === 'full' || ('sysadmin','admin','manager').indexOf(currentUser.role) >= 0" fab x-small v-on="on" @click.stop="confirmDialog(item)"><v-icon>delete</v-icon></v-btn>
                    </template>
                    <span>Delete Account</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog v-model="confirmDelete" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete Reservoir?</v-card-title>
                    <v-card-text>Are you sure you want to delete the reservoir: {{ current.name }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="deleteReservoir()">Yes</v-btn>
                        <v-btn color="green darken-1" text @click="confirmDelete = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import { mdiAccountPlus } from '@mdi/js';
    import resource from '@/resource';
    import EditDialog from './reservoir-edit-dialog';
    import AddDialog from './reservoir-add-dialog';
    import SendInviteDialog from '../accessInvites/accessInvite-send-dialog';

    export default {
        name: "reservoir-list",
        icons: {
            mdiAccountPlus
        },
        components: {
            EditDialog,
            AddDialog,
            SendInviteDialog
        },
        data () {
            return {
                current: {
                    address: {}
                    },
                selected: [],
                accessTypes: { "private": "Private", "public": "Public" },
                accessMethods: { "fifo": "FIFO", "lifo": "LIFO" },
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    },
                    { text: 'Access Type', value: 'accessType', sortable: true },
                    { text: 'Item Size (bytes)', value: 'maxItemSizeBytes' },
                    { text: 'Max Size (bytes)', value: 'maxSizeBytes' },
                    { text: 'Current Size (bytes)', value: 'currentSizeBytes' },
                    { text: 'Method', value: 'accessMethod' },
                    { text: 'Created', value: 'created' },
                    { value: 'actions' },
                ],
                reservoirs: [],
                reservoirsTotal: 0,
                reservoirSearch: "",
                currentPage: 1,
                perPage: 10,
                confirmDelete: false,
                showEdit: false,
                showAdd: false,
                showInvite: false,
                loading: false,
                currentUser: null,
                isAdmin: false,
                isManager: false
            }
        },
        watch: {
            showEdit: function(val) {
                if(val === false) this.getReservoirs(this.currentPage-1);
            },
            showAdd: function(val) {
                if(val === false) this.getReservoirs(this.currentPage-1);
            },
        },
        methods: {
            changePage: function(page) {
                this.getReservoirs(page-1);
            },
            getReservoirs: function(page) {
                if(!page) page = this.currentPage-1;
                this.loading = true;
                resource.reservoir.getReservoirs({ search: this.reservoirSearch, limit: this.perPage, offset: page }).then((result) => resource.processResponse(result, (res) => {
                    this.reservoirs = res.body.data.reservoirs;
                    this.accountsTotal = res.body.data.total;
                    this.loading = false;
                }), resource.processError);
            },
            editReservoir: function(client) {
                this.current = client;
                this.showEdit = true;
            },
            addReservoir: function() {
                this.showAdd = true;
            },
            addUser: function() {
                this.showUserAdd = true;
            },
            sendInvite: function() {
                this.showInvite = true;
            },
            confirmDialog: function(client) {
                this.current = client;
                this.confirmDelete = true;
            },
            deleteAccount: function() {
                resource.reservoir.deleteReservoir({ id: this.current._id }).then((result) => resource.processResponse(result, () => {
                    this.confirmDelete = false;
                    this.getReservoirs(this.currentPage-1);
                }), resource.processError)
            }
        },
        created() {
            this.getReservoirs(0);
            this.currentUser = this.$store.state.user;
        }
    }
</script>

<style scoped>

</style>