import { render, staticRenderFns } from "./reservoir-add-dialog.vue?vue&type=template&id=3199627e&scoped=true&"
import script from "./reservoir-add-dialog.vue?vue&type=script&lang=js&"
export * from "./reservoir-add-dialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3199627e",
  null
  
)

export default component.exports