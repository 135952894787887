var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"mini-variant":true,"app":"","clipped":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"link":"","to":"/dash/home"}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1)]}}])},[_c('span',[_vm._v("Dashboard")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"link":"","to":"/dash/reservoirs"}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("waves")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reservoirs")])],1)],1)]}}])},[_c('span',[_vm._v("Reservoirs")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"link":"","to":"/dash/schedules"}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-clock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Schedules")])],1)],1)]}}])},[_c('span',[_vm._v("Schedules")])]),(_vm.isAdmin === true)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"link":"","to":"/dash/settings"}},on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cog-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Settings")])],1)],1)]}}],null,false,1206426886)},[_c('span',[_vm._v("Settings")])]):_vm._e()],1)],1),_c('v-app-bar',{attrs:{"app":"","clipped-left":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',[_c('img',{staticStyle:{"height":"35px","position":"relative","left":"-15px"},attrs:{"alt":"echoing.io logo","src":require("@/assets/echoing_logo_white_nobg_small.png")}})]),_c('v-spacer'),_c('v-btn',[_vm._v(" "+_vm._s(_vm.user.username)+" ")]),_c('v-avatar',[_c('img',{attrs:{"alt":"User Avatar","src":_vm.avatarUrl}})]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.onLogout($event)}}},[_c('v-icon',[_vm._v("mdi-logout")])],1)],1),_c('v-content',{staticClass:"echo-dash-content"},[_c('v-container',{staticClass:"echo-dash-container fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"echo-dash-container-row fill-height"},[_c('div',{staticClass:"echo-dash-alerts",staticStyle:{"position":"absolute","top":"15px","right":"15px","width":"320px","z-index":"999"}},_vm._l((_vm.alerts),function(alert,index){return _c('v-alert',{key:index,attrs:{"border":"left","colored-border":"","type":alert.type,"dismissible":"","dense":"","dark":"","elevation":"2"}},[_vm._v(_vm._s(alert.message))])}),1),_c('v-col',{staticClass:"echo-dash-container-col",attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)],1),_c('v-footer',{attrs:{"app":""}},[_c('span',[_vm._v("echoing.io © 2020")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }