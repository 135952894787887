import Vue from 'vue';
import store from '@/store';

function User() {
  this.name = "User";
}

User.prototype.addUser = (newUser) => Vue.http.post(store.state.apiUrl + 'user/', newUser);
User.prototype.deleteUser = (params) => Vue.http.delete(store.state.apiUrl + 'user/' + params.id);
User.prototype.updateUser = (user) => Vue.http.put(store.state.apiUrl + 'user/', user);
User.prototype.getUser = (params) => Vue.http.get(store.state.apiUrl + 'user/' + params.id);
User.prototype.getUsers = (params) => Vue.http.get(store.state.apiUrl + 'user', { params: { search: params.search, limit: params.limit, offset: params.offset }});
User.prototype.getRoles = () => Vue.http.get(store.state.apiUrl + 'user/roles');
User.prototype.getInvite = (params) => Vue.http.get(store.state.apiUrl + 'user/' + params.id + '/invite');
User.prototype.resendInvite =  (params) => Vue.http.get(store.state.apiUrl + 'user/' + params.id + '/resend');
User.prototype.acceptInvite = (params) => Vue.http.put(store.state.apiUrl + 'user/accept', { _id: params.id, password: params.password });

export default User;
