import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://api.echoing.io/api/",
    authUrl: "https://api.echoing.io/auth/login",
    logoutUrl: "https://api.echoing.io/auth/logout",
    checkUrl: "https://api.echoing.io/auth/check",
    uploadUrl: "https://api.echoing.io/upload/",
    vm: null,
    user: null,
    alerts: [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setVm(state, vm) {
      state.vm = vm;
    },
    addAlert(state, alert) {
      // We can use this timestamp to clear old alerts.
      alert.created = (new Date()).getTime();
      state.alerts.push(alert);
    },
    deleteAlert(state, index) {
      state.alerts.splice(index, 1);
    },
    flushAlerts(state) {
      let now = (new Date).getTime();

      // Look through the alerts and delete the old ones
      for(let i = 0; i < state.alerts.length; i++)
      {
        if(now - state.alerts[i].created > 5000) {
          state.alerts.splice(i,1);
          i--;
        }
      }
    }
  }
});
