import Vue from 'vue';
import store from '../../store';

function Reservoir() {
    this.name = "Reservoir";
}

Reservoir.prototype.addReservoir = (newReservoir) => Vue.http.post(store.state.apiUrl + 'reservoir/', newReservoir);
Reservoir.prototype.updateReservoir = (reservoir) => Vue.http.put(store.state.apiUrl + 'reservoir/', reservoir);
Reservoir.prototype.deleteReservoir = (params) => Vue.http.delete(store.state.apiUrl + 'reservoir/' + params.id);
Reservoir.prototype.getReservoir =  (params) => Vue.http.get(store.state.apiUrl + 'reservoir/' + params.id);
Reservoir.prototype.getReservoirs =  (params) => Vue.http.get(store.state.apiUrl + 'reservoir/?search=' + params.search);

export default Reservoir;