<template>
    <v-app id="echoing">
        <v-content>
            <v-form @submit="onSubmit">
                <v-container
                        class="fill-height"
                        fluid
                >
                    <v-row
                            align="center"
                            justify="center"
                    >
                        <v-col
                                cols="12"
                                sm="8"
                                md="4"
                        >
                            <v-card class="elevation-12">
                                <v-toolbar
                                        color="primary"
                                        dark
                                        flat
                                >
                                    <v-toolbar-title>Login form</v-toolbar-title>
                                    <v-spacer />
                                    <img src="@/assets/echoing_logo_white_nobg.png" style="width: 120px;">
                                </v-toolbar>
                                <v-card-text>
                                    <v-form>
                                        <v-alert border="left"
                                                 colored-border
                                                 :type="alert.type"
                                                 :key="alert.message"
                                                 elevation="2"
                                                 v-for="alert in alerts"
                                        >{{ alert.message }}</v-alert>
                                        <v-text-field
                                                label="Login"
                                                name="login"
                                                prepend-icon="person"
                                                type="text"
                                                v-model="username"
                                        />

                                        <v-text-field
                                                id="password"
                                                label="Password"
                                                name="password"
                                                prepend-icon="lock"
                                                type="password"
                                                v-model="password"
                                        />
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="primary" type="submit">Login</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-content>
    </v-app>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        name: "login-page",
        data: function() {
            return {
                username: null,
                password: null,
                alerts: this.$store.state.alerts,
            };
        },
        methods: {
            onSubmit: function() {
                this.$http.post(this.$store.state.authUrl, { username: this.username, password: this.password}).then(res => {
                    if(res.body.status === 'OK') {
                        this.$store.commit('setUser', res.body.data);
                        this.$router.push('/dash/home');
                    }
                    else
                    {
                        this.$store.commit('addAlert',{ type: "error", message: "Failed to login: " + res.body.message });
                    }
                }, res => {
                    this.$store.commit('addAlert', { type: "error", message: "Login request failed: " + res.statusText });
                })
            }
        },
        created() {
            // Add query string message to alerts
            if(this.$route.query.type) this.alerts.push({ variant: this.$route.query.type, message: this.$route.query.message });
            this.$http.get(this.$store.state.checkUrl).then(res => {
                if(res.body.status === 'OK') {
                    this.$store.commit('setUser', res.body.data);
                    this.$router.push('/dash/home');
                }
            }, res => {
                this.$store.commit('addAlert',{ type: "error", message: "Auth check request failed: " + res.statusText });
            });
        }
    }
</script>
<style>
    #echoing {
        background-image: url('../assets/login_background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%
    }
</style>