import { render, staticRenderFns } from "./accessKey-edit-dialog.vue?vue&type=template&id=cc5c1e70&scoped=true&"
import script from "./accessKey-edit-dialog.vue?vue&type=script&lang=js&"
export * from "./accessKey-edit-dialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc5c1e70",
  null
  
)

export default component.exports