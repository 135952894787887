import Vue from 'vue';
import store from '../../store';

function AccessKey() {
    this.name = "Reservoir";
}

AccessKey.prototype.addAccessKey = (newReservoir) => Vue.http.post(store.state.apiUrl + 'accessKey/', newReservoir);
AccessKey.prototype.updateAccessKey = (reservoir) => Vue.http.put(store.state.apiUrl + 'accessKey/', reservoir);
AccessKey.prototype.deleteAccessKey = (params) => Vue.http.delete(store.state.apiUrl + 'accessKey/' + params.id);
AccessKey.prototype.getAccessKey =  (params) => Vue.http.get(store.state.apiUrl + 'accessKey/' + params.id);
AccessKey.prototype.getAccessKeys =  (params) => Vue.http.get(store.state.apiUrl + 'accessKey/?search=' + params.search);

export default AccessKey;