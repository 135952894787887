<template>
    <div>
        <EditDialog v-model="current" v-bind:show.sync="showEdit"></EditDialog>
        <AddDialog v-bind:show.sync="showAdd"></AddDialog>
        <div v-if="loading == true" class="text-center" style="margin-top: 100px;">
            <v-progress-circular :indeterminate=true></v-progress-circular>
        </div>
        <v-data-table
                v-if="loading == false"
                v-model="selected"
                :headers="headers"
                :items="users"
                item-key="name"
                class="elevation-1"
        >
            <template v-slot:top>
                <v-layout style="margin: 10px;">
                    <h2 style="margin-right: 30px;">Users</h2>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="addUser()">
                        <v-icon>mdi-plus</v-icon>
                        Add User
                    </v-btn>
                </v-layout>
            </template>
            <template v-slot:item.role="{ item }">
                {{ getRoleName(item.role) }}
            </template>
            <template v-slot:item.updated="{ item }">
                {{ item.updated | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.created="{ item }">
                {{ item.created | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" @click.stop="editUser(item)"><v-icon>edit</v-icon></v-btn>
                    </template>
                    <span>Edit User</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" @click.stop="confirmDialog(item)"><v-icon>delete</v-icon></v-btn>
                    </template>
                    <span>Delete
                        User</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog v-model="confirmDelete" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete User?</v-card-title>
                    <v-card-text>Are you sure you want to delete the user: {{ current.username }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="deleteUser()">Yes</v-btn>
                        <v-btn color="green darken-1" text @click="confirmDelete = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import resource from '@/resource';
    import EditDialog from './user-edit-dialog';
    import AddDialog from './user-add-dialog';

    export default {
        name: "user-list",
        components: {
            EditDialog,
            AddDialog
        },
        data () {
            return {
                current: { _account: {} },
                selected: [],
                roles: [],
                headers: [
                    {
                        text: 'Username',
                        align: 'start',
                        sortable: true,
                        value: 'username',
                    },
                    { text: 'Forename', value: 'forename' },
                    { text: 'Surname', value: 'surname' },
                    { text: 'Role', value: 'role' },
                    { text: 'Updated', value: 'updated' },
                    { text: 'Created', value: 'created' },
                    { value: 'actions' },
                ],
                users: [],
                usersTotal: 0,
                userSearch: "",
                currentPage: 1,
                confirmDelete: false,
                showEdit: false,
                showAdd: false,
                loading: false
            }
        },
        watch: {
            showEdit: function(val) {
                if(val === false) this.getUsers(this.currentPage-1);
            },
            showAdd: function(val) {
                if(val === false) this.getUsers(this.currentPage-1);
            },
        },
        methods: {
            changePage: function(page) {
                this.getUsers(page-1);
            },
            getRoles: function() {
                resource.user.getRoles().then(res => {
                    if(res.body.status === 'OK') {
                        this.roles = res.body.data;
                        this.current.role = 'user';
                    }
                }, resource.processError);
            },
            getRoleName: function(role) {
                if(this.roles.length > 0) return this.roles.find(r => r.value === role).text;
                return role;
            },
            getUsers: function(page) {
                if(!page) page = this.currentPage-1;
                this.loading = true;
                resource.user.getUsers({ search: this.userSearch, limit: this.perPage, offset: page }).then((result) => resource.processResponse(result, (res) => {
                    this.users = res.body.data.users;
                    this.usersTotal = res.body.data.total;
                    this.loading = false;
                }), resource.processError);
            },
            editUser: function(user) {
                this.current = user;
                this.showEdit = true;
            },
            addUser: function() {
                this.showAdd = true;
            },
            confirmDialog: function(user) {
                this.current = user;
                this.confirmDelete = true;
            },
            deleteUser: function() {
                resource.user.deleteUser({ id: this.current._id }).then((result) => resource.processResponse(result, () => {
                    this.confirmDelete = false;
                    this.getUsers(this.currentPage-1);
                }), resource.processError)
            }
        },
        created() {
            this.getRoles();
            this.getUsers(0);
        }
    }
</script>

<style scoped>

</style>