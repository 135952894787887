<template>
    <div>
        <EditDialog v-model="current" v-bind:show.sync="showEdit"></EditDialog>
        <AddDialog v-bind:show.sync="showAdd"></AddDialog>
        <AddUserDialog v-bind:show.sync="showUserAdd"></AddUserDialog>
        <div v-if="loading == true" class="text-center" style="margin-top: 100px;">
            <v-progress-circular :indeterminate=true></v-progress-circular>
        </div>
        <v-data-table
                v-if="loading == false"
                v-model="selected"
                :headers="headers"
                :items="accounts"
                item-key="name"
                class="elevation-1"
        >
            <template v-slot:top>
                <v-layout style="margin: 10px;">
                    <h2 style="margin-right: 30px;">Accounts</h2>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="addAccount()">
                        <v-icon>mdi-plus</v-icon>
                        Add Account
                    </v-btn>
                    <v-btn @click.stop="addUser()" style="margin-left: 5px;">
                        <v-icon style="margin-right: 5px;">mdi-account-plus</v-icon>
                        Add User
                    </v-btn>
                </v-layout>
            </template>
            <template v-slot:item.updated="{ item }">
                {{ item.updated | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.created="{ item }">
                {{ item.created | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" @click.stop="editAccount(item)"><v-icon>edit</v-icon></v-btn>
                    </template>
                    <span>Edit Account</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" @click.stop="confirmDialog(item)"><v-icon>delete</v-icon></v-btn>
                    </template>
                    <span>Delete Account</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog v-model="confirmDelete" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete Account?</v-card-title>
                    <v-card-text>Are you sure you want to delete the account: {{ current.name }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="deleteAccount()">Yes</v-btn>
                        <v-btn color="green darken-1" text @click="confirmDelete = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import { mdiAccountPlus } from '@mdi/js';
    import resource from '@/resource';
    import EditDialog from './account-edit-dialog';
    import AddDialog from './account-add-dialog';
    import AddUserDialog from '../user/user-add-dialog';

    export default {
        name: "account-list",
        icons: {
            mdiAccountPlus
        },
        components: {
            EditDialog,
            AddDialog,
            AddUserDialog
        },
        data () {
            return {
                current: {
                    address: {}
                    },
                selected: [],
                headers: [
                    {
                        text: 'Name',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    },
                    { text: 'Total Users', value: 'userCount' },
                    { text: 'Max Users', value: 'maxUsers' },
                    { text: 'Updated', value: 'updated' },
                    { text: 'Created', value: 'created' },
                    { value: 'actions' },
                ],
                accounts: [],
                accountsTotal: 0,
                accountSearch: "",
                currentPage: 1,
                perPage: 10,
                confirmDelete: false,
                showEdit: false,
                showAdd: false,
                showUserAdd: false,
                loading: false
            }
        },
        watch: {
            showEdit: function(val) {
                if(val === false) this.getAccounts(this.currentPage-1);
            },
            showAdd: function(val) {
                if(val === false) this.getAccounts(this.currentPage-1);
            },
        },
        methods: {
            changePage: function(page) {
                this.getAccounts(page-1);
            },
            getAccounts: function(page) {
                if(!page) page = this.currentPage-1;
                this.loading = true;
                resource.account.getAccounts({ search: this.accountSearch, limit: this.perPage, offset: page }).then((result) => resource.processResponse(result, (res) => {
                    this.accounts = res.body.data.accounts;
                    this.accountsTotal = res.body.data.total;
                    this.loading = false;
                }), resource.processError);
            },
            editAccount: function(client) {
                this.current = client;
                this.showEdit = true;
            },
            addAccount: function() {
                this.showAdd = true;
            },
            addUser: function() {
                this.showUserAdd = true;
            },
            confirmDialog: function(client) {
                this.current = client;
                this.confirmDelete = true;
            },
            deleteAccount: function() {
                resource.account.deleteAccount({ id: this.current._id }).then((result) => resource.processResponse(result, () => {
                    this.confirmDelete = false;
                    this.getAccounts(this.currentPage-1);
                }), resource.processError)
            }
        },
        created() {
            this.getAccounts(0);
        }
    }
</script>

<style scoped>

</style>