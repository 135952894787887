// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
        themes: {
            dark: {
                primary: "#00AAB9",
                secondary: "#00AAB9",
                accent: "#00AAB9",
            }
        },
        dark: true,
    }
}

export default new Vuetify(opts)