import Vue from 'vue';
import store from '../../store';

function Account() {
    this.name = "Account";
}

Account.prototype.addAccount = (newAccount) => Vue.http.post(store.state.apiUrl + 'account/', newAccount);
Account.prototype.deleteAccount = (params) => Vue.http.delete(store.state.apiUrl + 'account/' + params.id);
Account.prototype.getAccount =  (params) => Vue.http.get(store.state.apiUrl + 'account/' + params.id);
Account.prototype.getAccounts =  (params) => Vue.http.get(store.state.apiUrl + 'account/?search=' + params.search);

export default Account;