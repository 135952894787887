<template>
    <v-row justify="center">
        <v-dialog v-model="showDialog"
                  persistent
                  max-width="600px"
                  overlay-color="#666666"
                  overlay-opacity="0.8">
            <v-card>
                <v-card-title>
                    <span class="headline">Add Account</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field label="Account Name*" v-model="current.name" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Address Line 1*" v-model="current.address.address1" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Address Line 2" v-model="current.address.address2"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Address Line 3" v-model="current.address.address3"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Town/City*" v-model="current.address.city" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="County/State*" v-model="current.address.county" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field label="Postcode/Zip" v-model="current.address.postcode"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        :items=countryList
                                        v-model="current.address.country"
                                        label="Country*"
                                        item-text="Name"
                                        item-value="Name"
                                        required
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        label="Billing Email"
                                        type="email"
                                        v-model="current.billingEmailAddress"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field label="Max Users" v-model="current.maxUsers" type="number" step="1" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-text-field label="Billed Users" v-model="current.billedUsers" type="number" step="1" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                                <v-select
                                        :items='["None", "Monthly", "Quarterly", "Annually"]'
                                        v-model="current.billingPeriod"
                                        label="Billing Period"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#00AAB9" text @click="showDialog = false">Close</v-btn>
                    <v-btn color="#00AAB9" text @click="saveAccount()">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import resource from '@/resource';
    import countryList from '@/assets/lookups/country-list.json';

    export default {
        name: "user-edit",
        props: {
            value: Object,
            show: Boolean
        },
        data() {
            return {
                roles: [],
                countryList: countryList,
                current: {
                    address: {}
                },
                rules: {
                    confirmPassword: v => v == this.current.password || 'Passwords must match.',
                }
            }
        },
        methods: {
            saveAccount: function() {
                let newAccount = {
                    name: this.current.name,
                    address: this.current.address,
                    billingEmailAddress: this.current.billingEmailAddress,
                    maxUsers: this.current.maxUsers,
                    billedUsers: this.current.billedUsers,
                    billingPeriod: this.current.billingPeriod
                }

                resource.account.addAccount(newAccount).then(res => {
                    if(res.body.status == 'OK') {
                        this.current.updated = true;
                        this.showDialog = false;
                    }
                }, resource.processError);
            }
        },
        computed: {
            showDialog: {
                get() {
                    return this.show
                },
                set (value) {
                    this.$emit('update:show', value);
                }
            }
        },
        created() {
        }
    }
</script>

<style scoped>

</style>