import Vue from 'vue'
import VueRouter from 'vue-router';
import VueResource from 'vue-resource';
import VueClipboards from 'vue-clipboards';

import moment from 'moment';
import VueMoment from 'vue-moment';

import App from './App.vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export

import store from './store';
import loginPage from "@/components/login-page";
import invitePage from "@/components/invite-page";
import dashboardPage from "@/components/dashboard-page";
import homePage from "@/components/home/home-page";
import settingsPage from "@/components/settings/settings-page";
import reservoirPage from "@/components/reservoir/reservoir-page";


Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(VueClipboards);
Vue.use(VueMoment, { moment });

// Let do some stuff to allow CORS
Vue.http.options.credentials = true;

const routes = [
  { path: '/', component: loginPage },
  { path: '/invite', component: invitePage },
  { title: 'Dashboard', path: '/dash', component: dashboardPage, children: [
      { title: 'Home', path: '/dash/home', component: homePage },
      { title: 'Reservoirs', path: '/dash/reservoirs', component: reservoirPage },
      { title: 'Settings', path: '/dash/settings', component: settingsPage },
      /*{ title: 'Administration', path: '/home/admin', component: AdminComponent, children: [
          { title: 'Users', path: '/home/admin/users', component: AdminUserComponent }
        ]},*/
    ]
  },
];

const router = new VueRouter({
  routes: routes
});

let vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
});
store.commit('setVm', vm);
vm.$mount('#app');

// This interval will be used to perform ations on a timer
setInterval(() => {
    store.commit('flushAlerts');
}, 1000);
