<template>
    <div>
        <EditDialog v-model="current" v-bind:show.sync="showEdit"></EditDialog>
        <SendInviteDialog v-bind:show.sync="showInvite"></SendInviteDialog>
        <div v-if="loading === true" class="text-center" style="margin-top: 100px;">
            <v-progress-circular :indeterminate=true></v-progress-circular>
        </div>
        <v-data-table
                v-if="loading === false"
                v-model="selected"
                :headers="headers"
                :items="accessKeys"
                item-key="_id"
                class="elevation-1"
        >
            <template v-slot:top>
                <v-layout style="margin: 10px;">
                    <h2 style="margin-right: 30px;">Access Keys</h2>
                    <v-spacer></v-spacer>
                    <v-btn @click.stop="sendInvite()">
                        <v-icon style="margin-right: 5px;">mdi-email</v-icon>
                        Send Invite
                    </v-btn>
                </v-layout>
            </template>
            <template v-slot:item.created="{ item }">
                {{ item.created | moment("YYYY-MM-DD HH:mm:ss") }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" @click.stop="editAccessKey(item)"><v-icon>edit</v-icon></v-btn>
                    </template>
                    <span>Edit Account</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" @click.stop="confirmDialog(item)"><v-icon>delete</v-icon></v-btn>
                    </template>
                    <span>Delete Access Key</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn fab x-small v-on="on" v-clipboard="item.key" @success="copyOK" @error="copyError"><v-icon>mdi-text-box-multiple-outline</v-icon></v-btn>
                    </template>
                    <span>Copy Access Key</span>
                </v-tooltip>
            </template>
        </v-data-table>
        <v-row justify="center">
            <v-dialog v-model="confirmDelete" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Delete Reservoir?</v-card-title>
                    <v-card-text>Are you sure you want to delete the API key for: {{ current._user.username }}@{{ current._reservoir.name }}?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="deleteApiKey()">Yes</v-btn>
                        <v-btn color="green darken-1" text @click="confirmDelete = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import { mdiAccountPlus } from '@mdi/js';
    import resource from '@/resource';
    import EditDialog from './accessKey-edit-dialog';
    import SendInviteDialog from '../accessInvites/accessInvite-send-dialog';

    export default {
        name: "reservoir-list",
        icons: {
            mdiAccountPlus
        },
        components: {
            EditDialog,
            SendInviteDialog
        },
        data () {
            return {
                current: {
                    name: "",
                    _user: { username: "" },
                    _reservoir: { name: "" },
                    _account: { name: "" },
                    notification: {},
                    channels: {}
                },
                selected: [],
                headers: [
                    {
                        text: 'Account',
                        align: 'start',
                        sortable: true,
                        value: '_account.name',
                    },
                    { text: 'User', value: '_user.username', sortable: true },
                    { text: 'Reservoir', value: '_reservoir.name' },
                    { text: 'Access', value: 'access' },
                    { text: 'Active', value: 'active' },
                    { text: 'Created By', value: 'createdByUser.username' },
                    { text: 'Created', value: 'created' },
                    { value: 'actions' },
                ],
                accessKeys: [],
                accessKeyTotal: 0,
                accessKeySearch: "",
                currentPage: 1,
                perPage: 10,
                confirmDelete: false,
                showEdit: false,
                showInvite: false,
                loading: false
            }
        },
        watch: {
            showEdit: function(val) {
                if(val === false) this.getAccessKeys(this.currentPage-1);
            },
            showAdd: function(val) {
                if(val === false) this.getAccessKeys(this.currentPage-1);
            },
        },
        methods: {
            copyOK: function() {
                this.$store.commit('addAlert', { type: 'success', message: 'Access key copied to clipboard.' });
            },
            copyError: function() {
                this.$store.commit('addAlert', { type: 'error', message: 'Failed to copy access key to clipboard.' });
            },
            changePage: function(page) {
                this.getAccessKeys(page-1);
            },
            getAccessKeys: function(page) {
                if(!page) page = this.currentPage-1;
                this.loading = true;
                resource.accessKey.getAccessKeys({ search: this.accessKeySearch, limit: this.perPage, offset: page }).then((result) => resource.processResponse(result, (res) => {
                    this.accessKeys = res.body.data.accessKeys;
                    this.accessKeyTotal = res.body.data.total;
                    this.loading = false;
                }), resource.processError);
            },
            editAccessKey: function(accessKey) {
                this.current = accessKey;
                this.showEdit = true;
            },
            sendInvite: function() {
                this.showInvite = true;
            },
            confirmDialog: function(accessKey) {
                this.current = accessKey;
                this.confirmDelete = true;
            },
            deleteAccount: function() {
                resource.accessKey.deleteAccessKey({ id: this.current._id }).then((result) => resource.processResponse(result, () => {
                    this.confirmDelete = false;
                    this.getAccessKeys(this.currentPage-1);
                }), resource.processError)
            }
        },
        created() {
            this.getAccessKeys(0);
        }
    }
</script>

<style scoped>

</style>