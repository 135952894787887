var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EditDialog',{attrs:{"show":_vm.showEdit},on:{"update:show":function($event){_vm.showEdit=$event}},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}}),_c('AddDialog',{attrs:{"show":_vm.showAdd},on:{"update:show":function($event){_vm.showAdd=$event}}}),_c('AddUserDialog',{attrs:{"show":_vm.showUserAdd},on:{"update:show":function($event){_vm.showUserAdd=$event}}}),(_vm.loading == true)?_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"100px"}},[_c('v-progress-circular',{attrs:{"indeterminate":true}})],1):_vm._e(),(_vm.loading == false)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.accounts,"item-key":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-layout',{staticStyle:{"margin":"10px"}},[_c('h2',{staticStyle:{"margin-right":"30px"}},[_vm._v("Accounts")]),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){$event.stopPropagation();return _vm.addAccount()}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Account ")],1),_c('v-btn',{staticStyle:{"margin-left":"5px"},on:{"click":function($event){$event.stopPropagation();return _vm.addUser()}}},[_c('v-icon',{staticStyle:{"margin-right":"5px"}},[_vm._v("mdi-account-plus")]),_vm._v(" Add User ")],1)],1)]},proxy:true},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated,"YYYY-MM-DD HH:mm:ss"))+" ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created,"YYYY-MM-DD HH:mm:ss"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editAccount(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Account")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDialog(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Account")])])]}}],null,false,1280806430),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.confirmDelete),callback:function ($$v) {_vm.confirmDelete=$$v},expression:"confirmDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Delete Account?")]),_c('v-card-text',[_vm._v("Are you sure you want to delete the account: "+_vm._s(_vm.current.name))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteAccount()}}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.confirmDelete = false}}},[_vm._v("No")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }