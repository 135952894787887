<template>
    <v-app id="echoing">
        <v-content>
            <v-form @submit="onSubmit">
                <v-container
                        class="fill-height"
                        fluid
                >
                    <v-row
                            align="center"
                            justify="center"
                    >
                        <v-col
                                cols="12"
                                sm="8"
                                md="4"
                        >
                            <v-card class="elevation-12">
                                <v-toolbar
                                        color="primary"
                                        dark
                                        flat
                                >
                                    <v-toolbar-title>User invite form</v-toolbar-title>
                                    <v-spacer />
                                    <img src="@/assets/echoing_logo_white_nobg.png" style="width: 120px;">
                                </v-toolbar>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field
                                                label="Username"
                                                name="username"
                                                prepend-icon="person"
                                                type="text"
                                                v-model="current.username"
                                                :readonly=true
                                        />

                                        <v-text-field
                                                label="Forename"
                                                name="forename"
                                                prepend-icon="mdi-account-box"
                                                type="text"
                                                v-model="current.forename"
                                                :readonly=true
                                        />

                                        <v-text-field
                                                label="Surname"
                                                name="surname"
                                                prepend-icon="mdi-account-box"
                                                type="text"
                                                v-model="current.surname"
                                                :readonly=true
                                        />

                                        <v-text-field
                                                id="password"
                                                label="Password"
                                                name="password"
                                                prepend-icon="lock"
                                                type="password"
                                                v-model="current.password"
                                        />

                                        <v-text-field
                                                id="confirm"
                                                :rules="[rules.confirmPassword]"
                                                label="Confirm Password*"
                                                prepend-icon="mdi-lock-question"
                                                type="password"
                                                v-model="current.confirmPassword"
                                        />
                                        <small style="margin-left: 15px;">By accepting this invite you agree to the echoing.io <a href="https://echoing.io/terms">terms and conditions</a>.</small>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="primary" type="submit">Accept Invite</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-content>
    </v-app>
</template>

<script>
    import {
        mdiAccountBox,
        mdiLockQuestion
    } from '@mdi/js';
    import resource from '@/resource';

    export default {
        props: {
            source: String,
        },
        name: "login-page",
        icons: {
            mdiAccountBox,
            mdiLockQuestion
        },
        data: function() {
            return {
                current: {},
                alerts: [],
                rules: {
                    confirmPassword: v => v == this.current.password || 'Passwords must match.',
                }
            };
        },
        methods: {
            dismissAlert: function(index) {
                this.alerts.splice(index, 1);
            },
            onSubmit: function() {
                resource.user.acceptInvite({ id: this.$route.query.uid, password: this.current.password} ).then(res => {
                        if(res.data.status == 'OK') {
                            this.$router.push({
                                path: '/',
                                query: { type: "success", message: "Your invite has been accepted, please login below."}
                            });
                        }
                        else
                        {
                            this.$router.push({
                                path: '/',
                                query: { type: "warning", message: "Your invite has already been accepted, please login below."}
                            });
                        }
                    },
                    resource.processError);
            },
            getUser: function() {
                resource.user.getInvite({ id: this.$route.query.uid }).then(res => {
                    if(res.body.status == 'OK') {
                        this.current = res.body.data;
                    }
                    else
                    {
                        this.$router.push({
                            path: '/',
                            query: { type: "warning", message: "Your invite has already been accepted, please login below."}
                        });
                    }
                }, resource.processError);
            }
        },
        created() {
            this.getUser();
        }
    }
</script>
<style>
    #echoing {
        background-image: url('../assets/login_background.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%
    }
</style>